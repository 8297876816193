import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Fade from 'react-reveal/Fade'
import LightBox from '../components/LightBox'

class BlogRoll extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: 'hu-HU',
        }
    }
    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark


        return (
            <div
                className="content has-text-centered"


            >

                {posts &&
                    posts.map(({ node: post }) => (
                        <div key={post.id}>
                            {post.frontmatter.featuredimage ? (
                                <div>
                                    <p className="post-meta">


                                        <p
                                            className="title  is-size-4"

                                        >

                                            <Fade bottom>

                                                <h2>{post.frontmatter.title}</h2>
                                            </Fade>
                                        </p>

                                    </p>
                                    <Fade bottom>

                                        <PreviewCompatibleImage
                                            imageInfo={post.frontmatter.featuredimage[0]}
                                        />


                                        <LightBox images={post.frontmatter.featuredimage} />
                                        <Link className="button" to={post.fields.slug}>
                                            Tovább →
                                        </Link>
                                    </Fade>
                                </div>
                            ) : null}
                        </div>
                    ))}
            </div>

        )
    }
}
BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                allMarkdownRemark(
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                featuredimage{
                                    coverimages {
                                        childImageSharp {
                                        fluid(maxWidth: 2000, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                        }
                                
                            }
                        }
                    }
                }
            }
        `}
        render={data => <BlogRoll data={data} />}
    />
)
